<template>
  <v-container
    v-if="albaran"
    fluid
    grid-list-md
    class="view-prealbaran ajuste-layout"
  >
    <!-- Datos Prealbarán -->
    <div class="prealbaran-info">
      <label> <b>CÓDIGO: </b>{{ albaran.codigo }}</label>
      <!-- <label> <b>TOTAL: </b>{{ albaran.total }}</label> -->
      <label> <b>NOMBRE CLIENTE: </b>{{ albaran.nombrecliente }}</label>
      <label> <b>FIRMANTE: </b>{{ albaran.firmantecli }}</label>
      <label> <b>OBSERVACIONES: </b>{{ albaran.observaciones }}</label>
    </div>

      <v-btn @click="dialogalbaranemail=true" class="mb-3 mt-3" label> 
        <v-icon left>email</v-icon>Enviar email
      </v-btn> 

      <v-dialog v-model="dialogalbaranemail" persistent max-width="500px">
      <v-card style="background:#ffffff;">
        <v-card-title>
          <span class="headline">Enviar Albarán por email</span>
        </v-card-title>
        <v-card-text>
          <v-container>           
            <v-text-field
                v-model="albaran.codigo"
                label="Código albarán"
                disabled
                >
              </v-text-field>           
              <v-text-field
                v-model="albaran.email"
                ref="email"
                label="Email"
                >
              </v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#555555" text @click="dialogalbaranemail=false">Cancelar</v-btn>
          <v-btn color="#555555" text @click="enviarmail()">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="listado">
      <v-data-table
        class="tabla_escritorio"
        :headers="headers"
        :items="albaran.lineas"
        hide-default-footer
        :items-per-page="1000"
        item-key="item.b"
      >

        <template v-slot:item="{ item }">
          <tr :key="item.idlinea">
              <td class="text-xs-left">{{ item.referencia }}</td>
            <td class="text-xs-left col-descripcion">{{ item.descripcion }}</td>
            <td class="text-center col-descripcion">{{ item.cantidad }}</td>
            <!-- <td class="text-xs-left">{{ item.pvp | formatPrice | currency }}</td> -->
            <!-- <td class="text-xs-left">{{ item.pvp }}</td> -->

            <!-- <td class='text-xs-left'>{{ calcpvptotallinea(props) | formatPrice | currency }}</td> -->
            <!-- <td class="justify-center text-center">{{ item.pvp }}€</td> -->
            <!-- <td class="justify-center text-center"><div class="tachado" v-if="item.descuentos!=''">{{ item.texto_pvptotallinea }}</div>{{ item.texto_pvptotallineadescuentos }}</td> -->
          </tr>
          <!-- </tbody> -->
        </template>
        <template slot="no-data">
          <v-alert :value="true" color="white">{{ $t("carro_vacio") }}</v-alert>
        </template>
      </v-data-table>

      <!--Tabla version movil -->
      <div
        class="tabla_lineas_movil"
        v-for="item in albaran.lineas"
        :key="'movil_' + item.idlinea + '_'"
      >
        <v-chip v-if="item.referencia" class="mb-3" label color="#666666" text-color="white"> 
          <v-icon left>label</v-icon>{{item.referencia}}
        </v-chip>   
      
        <v-chip color="transparent" label style='margin-top:0!important;  margin-left: 10px;' class="mb-3 mr-3 prev_movil"> Cantidad {{ item.cantidad }}</v-chip>

        <div class="line">
           <label> {{ item.descripcion }}</label>
        </div>
      </div>
    </div>

  </v-container>
</template>

<script>
//import CarroLineal from "@/components/CarroLineal";
// import CarroMatricial from "@/components/CarroMatricial";
import { gw } from "@/gw.js";

export default {
  name: "albaran",
  mixins: [gw],
  data(vm) {
    return {
      albaran: {},
      date: new Date().toISOString().substr(0, 10),
      dateformated: "",
      dialogalbaranemail:false,
      menu: false,
      observaciones: "",
      headers: [
         {
          text: "Referencia",
          value: "referencia",
          sortable: false
        },
        {
          text: "Descripcion",
          value: "descripcion",
          sortable: false
        },
        {
          text: "Cantidad",
          value: "cantidad",
          sortable: false,
          align: "center"
        }
      ],
      dialog: false,
      articulo_ref: "",
      articulo_desc: "",
      articulo_desc_anterior: "",
      results_articulo: [],
      timeout: null,
      dialog_view: "insert",
      dialogdelete: {
        active: false,
        title: vm.$i18n.t("prealbaran_dialog_eliminar"),
        item:{}
      }
    };
  },
  mounted: function() {
      let _this = this;
      // Obtener carro
      let post = { codigo: this._routerRoot._route.params.codigo };
      this.api("/peticion/albaran/", post, true).then(
        _data => {
          if (_data.datos.albaran != undefined) {
            this.albaran = _data.datos.albaran;            

            if(this.albaran.email!="" && this.albaran.email!=null){
              this.albaran.email+=";";
            }

            this.setBreadcrumbs([
              {
                text: "Partes de servicios",
                disabled: false,
                to: "/ordenestrabajo"
              },
              {
                text: this.albaran.codorden,
                disabled: false,
                to: "/ordentrabajo/" + this.albaran.codorden
              },
              {
                text: this.albaran.codigo,
                disabled: true,
                to: ""
              }
            ]);
          }
          else{
            this.setMessage({
              active:true,
              icon:'error',
              coloricon:'#bd0909',
              text:'ERROR. No se encontraron datos o ha habido un error',
              buttons:[{
                text:'volver',
                to:'back'
              }]
            });
          }
        },
        err => {
          console.log(err);
        }
      );

    /*let post = {"coduser": coduser };
        this.api('/peticion/clientes', post, null).then(
            (_data) => {
              _this.cliente =  _data.datos.cliente
            },
            (err) => {
                console.log(err)
                this.snackbar.color = 'error';
                this.snackbar.text = this.$i18n.t('login_msg_error');
                this.snackbar.snackbar = true;
            }
        );;*/
  },
  watch: {
    /* articulo_ref(val) {
      if (val.length > 3) {
        this.buscararticulo(val, "referencia");
      }else{
         this.results_articulo = [];
      }
      this.articulo_ref = val;
    }, */
    articulo_desc(val) {
      //if (val.length >= 3 &&
      //(this.results_articulo.length==100 || this.results_articulo.length==0)
      //|| this.articulo_desc.length < this.articulo_desc_anterior.length) {

      if (val.length >= 3) {
        this.articulo_desc_anterior = val;
        this.closekeyboard();
        var _this = this;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function() {
          _this.buscararticulo(val);
        }, 500);
      } else if (val.length < 3) {
        this.results_articulo = [];
      }
      this.articulo_desc = val;
    }
  },
  methods: {
    btnRestCant(item) {
      if (item.cantidad > 1) {
        item.cantidad = parseFloat(item.cantidad) - 0.5;
        this.actualizarprealbaran(item);
      }
    },
    btnSumCant(item) {
      if (item.cantidad >= 0) {
        item.cantidad = parseFloat(item.cantidad) + 0.5;
        this.actualizarprealbaran(item);
      }
    },
    closedialog() {
      this.dialog = false;
      this.articulo_desc = "";
      this.articulo_desc_anterior = "";
      this.results_articulo = [];
      this.dialog_view = "insert";
      this.prealbaranlinea.cantidad = "1";
      this.prealbaranlinea.descripcion = "";
      this.prealbaranlinea.idprealbaran = "";
      this.prealbaranlinea.referencia = "";
    },
    enviarmail(item) {

      var filter = {};
      var data=[]; data[0]={};

      data[0]["prealbaran"] = false; //Crear prealbarán vacio, Si se envía a true no importa nada más.
      data[0]["recalculartotales"] = false; //Calcula los totales del prealbaran

      filter["codigo"] = this._routerRoot._route.params.codigo;
      //filter["idprealbaran"] = '';
      //filter["codorden"] = this._routerRoot._route.params.codorden;
      data[0]["codigo"] = this._routerRoot._route.params.codigo;
      //data[0]["idprealbaran"] = '';
      //data[0]["codorden"] = this._routerRoot._route.params.codorden;

      data[0]["albaranar"] = false; //Crear el albaran a partir del prealbaran

      if(this.albaran.email==""){this.toasted("Email incorrecto", "error", "error");return true;}
      var emailarray = this.albaran.email.split(";");
      for (let i = 0; i < emailarray.length; i++){
        if(emailarray[i]!="" && !this.validarEmail(emailarray[i])){
          //Si entramos aquí es que hay algún email que es incorrecto
          this.toasted("Email incorrecto", "error", "error");
          this.$refs.email.focus();
          return true;
        }
      }
      data[0]["email_envio"] = this.albaran.email;

      data[0]["firmadocli"] = false;
      data[0]["firma_dataURL"] = '';
      data[0]["firma_nombrefirmante"] = '';
      data[0]["observaciones"] = '';

      
      this.api("/peticion/eneboo/prealbaran", {'prealbaranes':{'filter':filter,'data':data}}, true).then(
        resp => {
          if (resp.success == 1) {
            //Todo Ok -> Redireccionar al prealbarán creado
            this.toasted("Se ha enviado el email correctamente.", "success", 'check');
            this.dialogalbaranemail=false;
          } else {
            //error
            this.toasted("No se ha enviado el email", "error", "error");
            console.log(resp);
          }
          // if(resp.success == 1 )  _this.toasted("Se ha " + texto + " al carro corectamente", "success", 'shopping_cart');
          // else _this.toasted("No se ha podido al carro", "error", 'shopping_cart');
        },
        err => {
          this.toasted("No se ha enviado el email, ERR!", "error", "error");
          console.log("Error no controlado API");
          console.log(err);
        }
      );
    },

    // insert(articulo) {
    //   this.prealbaranlinea.referencia = articulo.r === undefined ? this.prealbaranlinea.referencia : articulo.r;
    //   this.prealbaranlinea.descripcion = articulo.d === undefined ? this.prealbaranlinea.descripcion : articulo.d;
    //   this.prealbaranlinea.cantidad = this.prealbaranlinea.cantidad;
    //   this.prealbaranlinea.idprealbaran = this.prealbaran.idprealbaran;

    //   var data = {};
    //   data["coduser"] = this.auth.coduser;
    //   data["codcliente"] = this.auth.coduser;
    //   data["cantidad"] = this.prealbaranlinea.cantidad;
    //   data["referencia"] = this.prealbaranlinea.referencia;
    //   data["descripcion"] = this.prealbaranlinea.descripcion;
    //   data["idprealbaran"] = this.prealbaranlinea.idprealbaran;
    //   this.closedialog();
    //   let _this = this;
    //   this.api("/peticion/prealbaran/insert", data, true).then(
    //     _resp => {
    //       console.log(_resp);
          
    //       _this.prealbaran.lineas.push(_resp.datos);
    //       return true;
    //     },
    //     err => {
    //       console.log(err);
    //       return false;
    //     }
    //   );
    // },
    borrararticulo(item) {
      var data = {};
      data["referencia"] = item.referencia;
      data["numlinea"] = item.numlinea;
      data["idprealbaran"] = this.prealbaran.idprealbaran;
      data['idlinea'] = item.idlinea;
      this.dialogdelete.active = false;
      this.dialogdelete.item = {};
      let _this = this;
      this.api("/peticion/prealbaran/prealbara_remove", data, false).then(
        _resp => {
          let num = _resp.datos;
          _this.prealbaran.lineas.forEach((e, i) => {
            if (num == e.idlinea) {
              _this.prealbaran.lineas.splice(i, 1);
            }
          });
          return true;
        },
        err => {
          return false;
          console.log(err);
        }
      );
    },
    // buscararticulo(search) {
    //   let _this = this;
    //   let data = {};
    //   //if(kind == 'descripcion') data['descripcion'] = search;
    //   data["busqueda"] = search;
    //   this.api("/peticion/articulosfiltro/", data, false).then(
    //     resp => {
    //       if (resp.success == 1 && resp.datos != null) {
    //         //Todo Ok
    //         _this.results_articulo = resp.datos;
    //         console.log(_this.results_articulo);
    //       }
    //       console.log(resp);
    //     },
    //     err => {
    //       console.log(err);
    //     }
    //   );
    // },
    /*getfotoarticulos(referencia) {
      let img_path = [];
      img_path =
        process.env.VUE_APP_IMG_URL + "min/" + referencia + ".JPG" == undefined
          ? process.env.VUE_APP_IMG_URL + "min/" + referencia + ".JPG"
          : process.env.VUE_APP_IMG_URL + "nofoto.png";
      return img_path;
    },*/
    /*guardarpedido(){
        let post = {"coduser": coduser };
        this.api('/peticion/?/', post, null).then(
            (_data) => {
             //
            },
            (err) => {
                console.log(err)
                this.snackbar.color = 'error';
                this.snackbar.text = this.$i18n.t('login_msg_error');
                this.snackbar.snackbar = true;
            }
        );;
    },*/
    actualizarprealbaran(item) {
      var data = {};
      data["coduser"] = this.auth.coduser;
      data["codcliente"] = this.auth.cliente;
      data["cantidad"] = item.cantidad;
      data["referencia"] = item.referencia;
      data['idlinea'] = item.idlinea;

      let _this = this;
       clearTimeout(this.timeout);
        this.timeout = setTimeout(function() {
          _this.api("/peticion/albaran/update", data, false).then(
            _resp => {
              return true;
            },
            err => {
              return false;
              console.log(err);
            }
          );

        }, 500);
    }
  }
};
</script>
